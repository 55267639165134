<form>
  <div class="form-itens">
    <div>
      <label for="username">Nome</label>
      <input
        aria-label="Seu Nome"
        type="text"
        required
        id="username"
        name="name"
        pInputText
        [(ngModel)]="user.name"
        [ngClass]="{
          'error-input': userError?.errorName
        }"
      />
    </div>
    <div>
      <label for="lastName">Sobrenome</label>
      <input
        aria-label="Seu sobrenome"
        type="text"
        id="lastName"
        name="lastName"
        required
        pInputText
        [(ngModel)]="user.lastName"
        [ngClass]="{
          'error-input': userError?.errorLastName
        }"
      />
    </div>
  </div>

  <div class="form-itens">
    <div>
      <label for="document">CPF</label>
      <p-inputMask
        [disabled]="readonly"
        ariaLabel="Seu CPF"
        mask="999.999.999-99"
        name="document"
        id="document"
        [required]="true"
        [(ngModel)]="user.document"
        [ngClass]="{
          'error-input-mask': userError?.errorDocument
        }"
      ></p-inputMask>
    </div>

    <div>
      <label for="phone">Telefone</label>
      <p-inputMask
        ariaLabel="Seu telefone com DDD"
        mask="(99) 99999-9999"
        name="phone"
        id="phone"
        [required]="true"
        [(ngModel)]="user.phone"
        [ngClass]="{
          'error-input-mask': userError?.errorPhone
        }"
      ></p-inputMask>
    </div>

    <div>
      <label for="birthDate">Data Nascimento</label>
      <p-inputMask
        ariaLabel="Sua data de nascimento"
        mask="99/99/9999"
        name="birthDate"
        id="birthDate"
        [required]="true"
        [(ngModel)]="user.birthDate"
        [ngClass]="{
          'error-input-mask': userError?.errorBirthDate
        }"
      ></p-inputMask>
    </div>
  </div>

  <div class="form-itens">
    <div>
      <label for="email">E-mail</label>
      <input
        aria-label="Seu e-mail"
        type="email"
        name="email"
        id="email"
        required
        pInputText
        [(ngModel)]="user.email"
        [ngClass]="{
          'error-input': userError?.errorEmail
        }"
      />
    </div>
  </div>

  <div class="form-itens" *ngIf="!readonly">
    <div>
      <label for="password">Senha</label>
      <p-password
        ariaLabel="Informe sua senha"
        name="password"
        id="password"
        [required]="true"
        [(ngModel)]="user.password"
        (ngModelChange)="onChangePassword()"
        [toggleMask]="true"
        [feedback]="false"
        [ngClass]="{
          'error-input-prime': userError?.errorPassword
        }"
      ></p-password>
    </div>

    <div>
      <label for="confirmPassword">Confirmar Senha</label>
      <p-password
        ariaLabel="Confirme sua senha"
        [required]="true"
        name="confirmPassword"
        id="confirmPassword"
        [(ngModel)]="user.confirmPassword"
        (ngModelChange)="onChangePassword()"
        [toggleMask]="true"
        [feedback]="false"
        [ngClass]="{
          'error-input-prime': userError?.errorConfirmPassword
        }"
      ></p-password>
    </div>
  </div>
</form>
