<footer>
  <div class="footer-header">
    <div class="footer-header-item footer-header-item-logo">
      <a routerLink="/"
        ><img
          width="230"
          height="90"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo.png"
          loading="eager"
          alt="Logo Principal RM Policarbonatos e Acessórios"
      /></a>
      <h4>Uma Empresa do Grupo</h4>
      <a routerLink="/"
        ><img
          width="167"
          height="140"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/logo/logo-kgo.png"
          loading="eager"
          alt="Logo Principal Grupo KGO"
      /></a>
    </div>
    <div class="footer-header-item">
      <div class="footer-header-item-div">
        <h3>INFORMAÇÕES</h3>
        <a routerLink="/quem-somos">Sobre Nós</a>
        <a routerLink="/catalogos">Catálogos</a>
        <a routerLink="/area-do-cliente/meus-pedidos">Acompanhar Entrega</a>
        <a routerLink="/politicas-trocas-cancelamentos"
          >Trocas, Devoluções e Políticas de Entrega</a
        >
        <a routerLink="/termos-condicoes-uso">Termos e Condições</a>
        <a routerLink="/aviso-de-privacidade">Aviso de Privacidade</a>
        <a routerLink="/faq">Perguntas Frequentes</a>
        <a routerLink="/fale-conosco">Fale Conosco</a>
        <a routerLink="/seja-um-revendedor">Revendedor</a>
      </div>
    </div>
    <div class="footer-header-item">
      <div class="footer-header-item-div">
        <h3>ATENDIMENTO</h3>
        <h4>Segunda à Sexta <span>8h00 às 11:30 - 13:30 às 17:30</span></h4>
        <h4>Telefone<span>(48) 3369-7157</span></h4>
        <h4>Whatsapp<span>(48) 3369-7157</span></h4>
        <h4>
          comercial&#64;rmpolicarbonatos.com.br<span
            >sac&#64;rmpolicarbonatos.com.br</span
          >
        </h4>
      </div>
    </div>
    <div class="footer-header-item">
      <div class="footer-header-item-social">
        <a
          aria-label="Acesse nosso nossa página  no Facebook"
          href="https://www.facebook.com/rmpolicarbonatos/"
          target="_blank"
          class="facebook"
          ><i class="pi pi-facebook"></i
        ></a>
        <a
          aria-label="Acesse nosso perfil no Instagram"
          href="https://www.instagram.com/rmpolicarbonatos/"
          target="_blank"
          class="instagram"
          ><i class="pi pi-instagram"></i
        ></a>
        <a
          aria-label="Acesse nosso cannal no Youtube"
          href="https://www.youtube.com/channel/UC_SLAjoVniYGnfr3p0EvOyQ"
          target="_blank"
          class="youtube"
          ><i class="pi pi-youtube"></i
        ></a>
      </div>
      <div class="footer-header-item-partnes">
        <img
          width="140"
          height="31"
          class="secure"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/site-blindado-logo.webp"
          alt="Site blindado"
        />
        <img
          width="140"
          height="54"
          class="secure"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/amazon.webp"
          alt="Amazon Web Services"
        />
        <img
          width="140"
          height="61"
          class="secure"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/ssl-site-seguro.webp"
          alt="SSL Site Seguro"
        />
        <img
          width="50"
          height="35"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/visa.svg"
          alt="Cartão de Crédito Visa"
        />
        <img
          width="50"
          height="35"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/mastercard.svg"
          alt="Cartão de Crédito Master"
        />
        <img
          width="50"
          height="30"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/diners.svg"
          alt="Cartão de Crédito Diners"
        />
        <img
          width="50"
          height="35"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/amex.svg"
          alt="Cartão de Crédito Amex"
        />
        <img
          width="50"
          height="35"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/elo.svg"
          alt="Cartão de Crédito Elo"
        />
        <img
          width="50"
          height="35"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/hiper.svg"
          alt="Cartão de Crédito Hiper"
        />
        <img
          width="38"
          height="29"
          src="https://d20p8exwoll05u.cloudfront.net/assets/images/icon/billet.svg"
          alt="Pagamento em Boleto"
        />
      </div>
    </div>
  </div>
  <p class="copy-right">
    © 2024 Todos os direitos Reservados. KGO ZANGRANDI GROUP
  </p>
</footer>

<div
  class="whatsapp mobile-invisible"
  title="Acesse nosso Whatsapp"
  (click)="onClickbtnWhats()"
>
  <i class="pi pi-whatsapp"></i>
</div>
<app-whats-app
  *ngIf="showWhatsApp"
  (onClickbtnClose)="onClickbtnCloseWhats()"
  [productWhatsApp]="productWhatsApp"
></app-whats-app>
<div class="footer-cookies" *ngIf="showCookies">
  <div class="footer-cookies-main">
    <p>
      Utilizamos cookies para personalizar nossos anúncios e melhorar a sua
      experiência no site. Para saber mais, acesse o nosso
      <a target="_blank" href="aviso-de-privacidade">Aviso de Privacidade.</a>
    </p>
    <div class="footer-cookies-main-button" (click)="onClickCookies()">
      Aceitar e Fechar
    </div>
  </div>
</div>
