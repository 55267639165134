export const validPhone = (phone: string): boolean => {
  phone = phone?.replace(/\D/g, '');
  let valid = false;
  if (phone?.length > 9) {
    valid = true;
  }
  return valid;
};

export const validBirthDate = (dt: string): boolean => {
  if (dt?.length != 10) {
    return false;
  }

  const newDateString = getDateIso(dt);
  const newDate = new Date(newDateString);
  const invalidadeDate = newDate.toString();
  return !(invalidadeDate === 'Invalid Date');
};

export const validPassword = (
  password: string,
  confirmPassword: string
): string => {
  let msg = '';
  if (password && confirmPassword) {
    const valid = new RegExp('^^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])').test(
      password
    );
    if (password.length < 8 || !valid) {
      msg =
        'Sua senha deve conter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números';
    } else if (password !== confirmPassword) {
      msg = 'Senha e Confirmação estão diferente!';
    }
  } else {
    msg = 'Favor informar a senha e confirmação';
  }
  return msg;
};

export const validEmail = (email: string): boolean => {
  const valid = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  ).test(email);
  return valid;
};

export const validName = (name: string): boolean => {
  let valid = false;
  if (name?.length > 2) {
    valid = true;
  }
  return valid;
};

export const validZipCode = (zipcode: string): boolean => {
  let valid = false;
  if (zipcode?.length > 7) {
    valid = true;
  }
  return valid;
};

export const getDateIso = (value: string): string => {
  if (!value) {
    return '';
  }
  value = value.replace(/\D/g, '');
  const day = value.substring(0, 2);
  const month = value.substring(2, 4);
  const year = value.substring(4, 8);
  return `${year}-${month}-${day}`;
};
