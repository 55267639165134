<app-breadcrumb
  [path]="'Políticas, Trocas e Cancelamentos'"
  [showTitle]="true"
></app-breadcrumb>

<div class="policies-main">
  <div class="container">
    <div class="policies-main-content">
      <details>
        <summary>1. INDRODUÇÃO</summary>
        <p>
          A RM POLICARBONATOS oferece aos seus clientes produtos de qualidade,
          buscando parcerias com os melhores fornecedores do mercado.
        </p>
        <p>
          Caso seja necessário a devolução ou troca de um produto, será
          necessário seguir as diretrizes da política de devolução, de forma
          fácil e descomplicada.
        </p>
        <p>
          Confira as orientações a seguir para proceder a troca ou devolução de
          um produto. Esteja certo de que nossa equipe está disponível para
          ajudá-lo em cada etapa do processo, assegurando sua completa
          satisfação e segurança.
        </p>
      </details>
      <details>
        <summary>2. TROCA E ARREPENDIMENTO</summary>
        <p>
          Essas diretrizes garantem que nossos clientes possam realizar trocas e
          devoluções de maneira eficiente e sem contratempos, assegurando a
          integridade e a qualidade dos produtos adquiridos. A seguir,
          apresentamos as condições específicas aplicáveis a diferentes
          situações de compra e tipos de produtos, proporcionando clareza e
          transparência em cada etapa.
        </p>
        <div>
          <p>2.1. COMPRA ON-LINE</p>
          <p>
            Em caso de solicitação de troca e cor de espessura de um produto já
            recebido e conferido, é possível realizar a troca dentro do prazo de
            30 (trinta) dias, com incidência de cobrança do frete de devolução e
            envio arcado pelo cliente.
          </p>
          <p>
            A nova remessa será encaminhada somente após a devolução e a
            conferência do material original, assegurando que esteja em perfeito
            estado e na embalagem original.
          </p>
          <p>
            Para iniciar o processo de troca, o cliente deve preencher o
            formulário disponível na página do
            <a href="sac">Serviço de Atendimento ao Cliente (SAC) </a> em nosso
            site. Após o preenchimento, a nossa equipe orientará sobre os
            procedimentos de devolução e demais etapas necessárias.
          </p>

          <p>2.3. COMPRA EM LOJA FÍSICA</p>

          <p>
            Produtos adquiridos em nossa loja física podem ser trocados somente
            em casos de defeito confirmado.
          </p>
          <p>
            O cliente tem um prazo de 7 (sete) dias para reportar o problema.
            Para iniciar o processo de troca, o cliente deve preencher o
            formulário disponível na página do Serviço de Atendimento ao Cliente
            (SAC) em nosso site. Após o preenchimento, nossa equipe analisará a
            solicitação e entrará em contato para orientar sobre os próximos
            passos.
          </p>
          <p>
            É essencial que o produto esteja em sua condição original e
            acompanhe todos os acessórios e embalagens recebidos no momento da
            compra para uma avaliação adequada.
          </p>

          <p>2.4. PRODUTOS SOB MEDIDA</p>
          <p>
            Produtos feitos ou que tenham sido cortados sob medida,
            personalizados ou que apresentem qualquer indício de uso ou mau uso
            não estão sujeitos à troca ou devolução, exceto em casos de defeitos
            de fabricação comprovados.
          </p>
          <p>
            Nesses casos, o cliente deve preencher o formulário disponível na
            página do Serviço de Atendimento ao Cliente (SAC) em nosso site.
          </p>
          <p>
            Nossa política visa a garantia de que cada produto entregue atenda
            aos padrões de qualidade e especificação. Por isso, enfatizamos a
            importância de revisar cuidadosamente suas necessidades antes de
            encomendar produtos personalizados ou cortados sob medida, uma vez
            que, após a confirmação de recebimento em condições adequadas, esses
            itens não são passíveis de troca.
          </p>

          <p>2.5. CONDIÇÕES DO PRODUTO PARA TROCA</p>
          <p>
            Para que uma troca seja efetuada, é imprescindível que o produto
            retornado esteja em perfeitas condições de uso e funcionamento.
          </p>
          <p>
            O produto não deve apresentar defeitos, riscos, sinais de tombo ou
            qualquer outro dano que o torne impróprio para revenda.
          </p>
          <p>
            É necessário que todas as etiquetas internas e externas estejam
            intactas e que o produto não tenha sido aberto de forma a violar sua
            originalidade.
          </p>
          <p>
            Além disso, o produto não deve conter qualquer conteúdo de
            propriedade do cliente (se aplicável), deve ser acompanhado da nota
            fiscal e da embalagem original (mesmo que aberta), bem como todos os
            acessórios e, se aplicável, o manual do produto.
          </p>
          <p>
            Essas condições são essenciais para garantir que o produto possa ser
            revendido e que a troca seja realizada de forma eficiente e
            satisfatória.
          </p>

          <p>2.6. CONFERÊNCIA NO ATO DO RECEBIMENTO</p>
          <p>
            Ao receber um produto, é imprescindível que o cliente realize uma
            conferência imediata para assegurar que o item está de acordo com o
            que foi adquirido.
          </p>
          <p>
            É importante destacar que não há obrigação de manter a embalagem
            inviolada para exercer o direito de arrependimento, pois, muitas
            vezes, é necessário abrir a embalagem para verificar se o produto
            está em conformidade com as expectativas geradas no momento da
            compra virtual.
          </p>
          <p>
            Caso o produto apresente qualquer problema, como defeitos, danos ou
            incompatibilidade, ou se houver itens faltantes ou divergentes do
            pedido, é crucial notificar nossa equipe de atendimento ao cliente
            imediatamente.
          </p>
          <p>
            Aceitar o produto sem essa verificação pode limitar a possibilidade
            de trocas ou devoluções futuras.
          </p>
          <p>
            A RM Policarbonatos se compromete a facilitar esse processo para
            garantir a satisfação completa de nossos clientes.
          </p>
        </div>
      </details>

      <details>
        <summary>
          3. DESISTÊNCIA DA COMPRA ANTES DO RECEBIMENTO DA MERCADORIA
        </summary>
        <div>
          <p>
            Em casos de circunstâncias imprevistas que podem levar à necessidade
            de cancelar uma compra antes mesmo de receber o produto, a RM
            Policarbonatos oferece um procedimento claro e direto para o
            cancelamento de pedidos em diferentes etapas do processo de compra.
          </p>
          <p>
            Este item explica como proceder caso você decida desistir de sua
            compra antes do recebimento do produto, incluindo instruções
            específicas para situações após a emissão da nota fiscal e detalhes
            sobre o processo de reembolso, conforme o método de pagamento
            utilizado.
          </p>
          <p>
            É fundamental seguir os passos descritos para garantir que a
            desistência seja processada corretamente e sem imprevistos,
            respeitando tanto a política interna quanto a legislação vigente.
          </p>

          <p>3.1. DESISTÊNCIA APÓS EMISSÃO DA NOTA FISCAL</p>
          <p>
            Caso opte por cancelar sua compra após a emissão da nota fiscal e
            antes da entrega do produto, você deve recusar a entrega quando a
            transportadora tentar entregá-la.
          </p>
          <p>
            Imediatamente após a recusa, é necessário preencher o formulário de
            desistência na página do Serviço de Atendimento ao Cliente (SAC) em
            nosso website.
          </p>
          <p>
            Este passo é obrigatório para que a RM Policarbonatos possa proceder
            com o cancelamento e iniciar o processo de reembolso de maneira
            eficiente.
          </p>

          <p>3.2. REEMBOLSO</p>
          <p>
            Quando o produto chegar ao nosso Centro de Distribuição, iniciaremos
            o processo de reembolso utilizando o mesmo método de pagamento
            utilizado na compra original. O reembolso será processado de acordo
            com as seguintes condições, baseadas no método de pagamento:
          </p>
          <p>
            • Cartão de Crédito: O estorno será realizado nas faturas
            subsequentes, conforme os procedimentos da administradora do seu
            cartão. Este processo está sujeito aos prazos e regras estabelecidos
            por cada bandeira de cartão.
          </p>
          <p>
            • Boleto Bancário: A restituição será feita diretamente na conta
            corrente do titular que efetuou a compra, dentro de um prazo de até
            10 dias úteis após a análise do produto retornado.
          </p>
          <p>
            É obrigatório que os clientes cumpram com os termos de nossa
            Política de Troca e/ou Arrependimento e com a legislação aplicável.
            O não cumprimento dessas condições resultará na responsabilização do
            cliente pelos custos associados à devolução do produto.
          </p>
        </div>
      </details>
      <details>
        <summary>4. CONCLUSÃO</summary>
        <div>
          <p>
            A RM Policarbonatos está comprometida em garantir a satisfação e a
            confiança dos clientes em cada compra. A clareza e a transparência
            nas políticas de troca e devolução são fundamentais para manter uma
            relação de confiança duradoura.
          </p>
          <p>
            Esperamos que as diretrizes estabelecidas neste documento ajudem a
            esclarecer os procedimentos e facilitem a resolução de qualquer
            situação relacionada à troca ou devolução de produtos.
          </p>
          <p>
            Para quaisquer dúvidas ou informações adicionais, nossa equipe de
            atendimento ao cliente está à disposição para auxiliar.
          </p>
        </div>
      </details>

      <h2>Política de Frete</h2>
      <details>
        <summary>1. CONFERÊNCIA E AVARIAS</summary>
        <div>
          <p>
            <b
              >De acordo com a Lei nº 11.442/2007, é indispensável a conferência
              de TODO O MATERIAL no momento da entrega. Assim que o cliente
              assina o canhoto de conferência do transporte, confirma que a
              mercadoria foi recebida em perfeito estado e não será aceita troca
              por avarias.</b
            >
          </p>
        </div>
      </details>

      <details>
        <summary>2. VALOR E PAGAMENTO DO FRETE</summary>
        <div>
          <p>
            O valor do frete é calculado com base no peso, dimensões da
            embalagem do produto e a distância entre nosso centro de
            distribuição e o endereço de entrega.
          </p>
          <p>
            O pagamento do frete é de responsabilidade do cliente e deve ser
            realizado no momento da compra, ao selecionar a transportadora e o
            modo de encaminhamento de sua preferência.
          </p>
        </div>
      </details>

      <details>
        <summary>3. PRAZO DE EXPEDIÇÃO</summary>
        <div>
          <p>
            Os pedidos são enviados em até 2 (dois) dias úteis após a
            confirmação do pagamento. Os Produtos sob encomenda e/ou sob medida
            serão enviados após o período de fabricação especificado na
            descrição do produto.
          </p>
        </div>
      </details>

      <details>
        <summary>4. PRAZO DE ENTREGA</summary>
        <div>
          <p>
            As entregas de produtos da RM Policarbonatos são realizadas de
            segunda a sexta-feira, das 8h às 18h. O prazo de entrega começa a
            contar a partir da confirmação do pagamento e inclui apenas dias
            úteis, excluindo sábados, domingos e feriados.
          </p>
          <p>
            Os prazos poderão variar devido a fatores logísticos externos,
            dependendo das operações de nossos parceiros transportadores ou em
            situações de caso fortuito ou força maior, que estão fora do nosso
            controle.
          </p>
        </div>
      </details>

      <details>
        <summary>5. ATRASOS OU EXTRAVIOS</summary>
        <div>
          <p>
            Em caso de atraso, a RM Policarbonatos abrirá uma reclamação com a
            transportadora responsável. Respondemos as manifestações em até 5
            dias úteis, podendo estender-se para análise detalhada. Se a
            mercadoria não for localizada, o cliente pode optar pelo reembolso
            integral ou substituição do pedido sem custos adicionais.
          </p>
          <p>
            As respostas às reclamações são providenciadas em até 5 (cinco) dias
            úteis, mas nesse intervalo pode se estender se a situação exigir uma
            análise mais detalhada.
          </p>
          <p>
            Caso a mercadoria não seja localizada, o cliente terá a opção de ser
            reembolsado integralmente pelo valor pago ou de fazer um novo pedido
            de valor equivalente através do nosso site.
          </p>
        </div>
      </details>

      <details>
        <summary>6. ENDEREÇO INCORRETO OU CLIENTE AUSENTE</summary>
        <div>
          <p>
            A RM Policarbonatos não se responsabiliza por devoluções devido a
            erros de endereço fornecidos pelo cliente. É crucial que os dados
            cadastrais estejam corretos. Em caso de ausência do cliente nas duas
            tentativas de entrega, o custo do reenvio será cobrado do cliente.
          </p>
        </div>
      </details>

      <details>
        <summary>7. CONFERÊNCIA DAS MERCADORIAS</summary>
        <div>
          <p>
            Ao receber a mercadoria, o cliente deve abrir a embalagem na
            presença do entregador da transportadora e efetuar a conferência,
            inspecionando todo o material antes de assinar o canhoto de
            recebimento.
          </p>
          <p>
            Qualquer avaria ou divergência do produto adquirido deve ser
            reportada imediatamente, recusando o recebimento.
          </p>
        </div>
      </details>

      <details>
        <summary>8. PROCEDIMENTO EM CASO DE RECUSA</summary>
        <div>
          <p>
            Após a recusa, é necessário notificar a RM Policarbonatos
            imediatamente
            <a href="sac">através do formulário junto ao SAC,</a>
            disponibilizado no nosso site para que possamos agilizar a
            substituição do produto.
          </p>
        </div>
      </details>

      <details>
        <summary>9. COMPROMISSO E LIMITAÇÕES DAS TRANSPORTADORAS</summary>
        <div>
          <p>
            As entregas são realizadas até o endereço indicado, incluindo a
            portaria de edifícios e entrada de residências. Não realizamos
            agendamento de entregas, nem a instalação ou posicionamento do
            produto no interior do local.
          </p>
        </div>
      </details>

      <h2>CONDIÇÕES DE USO DE CUPONS DE DESCONTO - RM POLICARBONATOS</h2>
      <p>
        Para assegurar o desconto oferecido pelo cupom, é necessário aplicá-lo
        na página de finalização de compra e concluir o pedido imediatamente. A
        aplicação do cupom por si só não garante o desconto, especialmente
        durante períodos de alta demanda promocional. Importante: o valor do
        cupom não é reembolsável em dinheiro e não é válido para compras em
        lojas físicas.
      </p>
      <details>
        <summary>1. CONDIÇÕES GERAIS DE USO</summary>
        <div>
          <p>
            1.1. O uso do cupom é exclusivo para compras feitas no site
            rmpolicarbonatos.com.br, dentro do prazo e das condições
            especificadas na promoção.
          </p>
          <p>
            1.2. O código do cupom deve ser inserido exatamente como fornecido,
            respeitando letras maiúsculas e minúsculas.
          </p>
          <p>
            1.3. Os cupons expirados ou já utilizados não poderão ser
            reaplicados para obter descontos, ou seja, quando ocorrer o uso do
            cupom após o limite de sua utilização, o desconto associado ao
            código não poderá ser concedido.
          </p>
          <p>
            1.4. A validade e outras condições específicas de cada cupom serão
            informadas no ato da promoção.
          </p>
        </div>
      </details>

      <details>
        <summary>2. APLICAÇÃO DO CUPOM</summary>
        <div>
          <p>
            2.1. Selecione o produto desejado, adicione ao carrinho e prossiga
            para a finalização da compra.
          </p>
          <p>
            2.2. Identifique-se no site para continuar a compra, cadastrando se
            for novo cliente.
          </p>
          <p>
            2.3. Na etapa de pagamento, insira o código do cupom no campo
            destinado.
          </p>
          <p>
            2.4. Os descontos dos cupons não são acumulativos com outras
            promoções ou descontos do site, salvo indicação contrária divulgadas
            em cada promoção.
          </p>
          <p>
            2.5. O desconto aplica-se apenas ao valor do produto, não incluindo
            os custos de frete.
          </p>
          <p>
            2.6. Se o desconto do produto for inferior ao do cupom, prevalece o
            desconto do cupom sobre o preço original do produto.
          </p>
        </div>
      </details>

      <details>
        <summary>3. REGRAS ADICIONAIS</summary>
        <div>
          <p>
            3.1. Cada pedido pode beneficiar-se de apenas um cupom, e o desconto
            obtido não é cumulativo com outro cupom, produtos em ofertas ou
            outros descontos.
          </p>
          <p>
            3.2. O cupom de desconto é válido apenas para produtos adquiridos
            diretamente pelo site rmpolicarbonatos.com.br e não pode ser
            convertido em dinheiro em nenhuma hipótese.
          </p>
          <p>
            3.3. No caso de cancelamento ou devolução do pedido, o valor
            reembolsado será o efetivamente pago, já considerando o desconto.
          </p>
          <p>3.4. Cada cupom é válido para apenas um uso por CPF.</p>
        </div>
      </details>

      <details>
        <summary>4. DISPOSIÇÕES FINAIS</summary>
        <div>
          <p>
            4.1. A participação na promoção implica a aceitação total e
            irrestrita pelo Cliente de todos os itens deste Regulamento, seus
            termos e condições.
          </p>
          <p>
            4.2. O presente regulamento pode ser alterado, e a promoção,
            suspensa ou cancelada a qualquer momento por motivo de força maior
            ou outros fatores externos que afetem a execução.
          </p>
          <p>
            4.3. Para dúvidas, sugestões ou reclamações, entre em contato por
            meio do formulário de atendimento ao cliente disponível na página do
            <a href="sac">Serviço de Atendimento ao Consumidor (SAC)</a> do
            nosso site.
          </p>
        </div>
      </details>
    </div>
  </div>
</div>
