import { formatDate } from '@angular/common';
import Order from './order';

export default class OrderGrid {
  createdAt?: string;
  currentStatus?: string;
  estimateDate?: string;
  id?: number;
  marketPlace?: string;
  nameUser?: string;
  orderEntry?: string;
  totalValue?: number;

  updateOrderGridFromOrder(order: Order) {
    this.currentStatus = order?.currentStatus;
    this.estimateDate = formatDate(
      order?.dtEstimate || '',
      'dd/MM/yyyy HH:mm',
      'pt-PT'
    );
    this.nameUser = order?.nameUser;
    this.totalValue = order?.totalValue;
  }
}
