<swiper-container
  autoplay-delay="3000"
  autoplay-pause-on-mouse-enter="true"
  [breakpoints]="breakpoints"
  class="slider-swiper"
>
  <swiper-slide>
    <div class="slider-swiper-item" *noRender>
      <a [href]="'/coberturas-em-policarbonato/policarbonato-alveolar'">
        <img
          src="{{
            isMobile
              ? 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-1-mobile.webp'
              : 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-1.webp'
          }}"
          alt="Confira nossas promoções"
          [ngStyle]="{
            'width.px': isMobile ? screenWidth : screenWidth
          }"
          class="product-image"
          loading="eager"
        />
      </a>
    </div>
  </swiper-slide>

  <swiper-slide>
    <div class="slider-swiper-item" *noRender>
      <a [href]="'/persianas/rolo'">
        <img
          src="{{
            isMobile
              ? 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-2-mobile.webp'
              : 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-2.webp'
          }}"
          alt="Confira nossas promoções"
          [ngStyle]="{
            'width.px': isMobile ? screenWidth : screenWidth
          }"
          class="product-image"
          loading="eager"
        />
      </a>
    </div>
  </swiper-slide>

  <swiper-slide>
    <div class="slider-swiper-item" *noRender>
      <a [href]="'/kits/toldo-em-policarbonato'">
        <img
          src="{{
            isMobile
              ? 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-3-mobile.webp'
              : 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-3.webp'
          }}"
          alt="Confira nossas promoções"
          [ngStyle]="{
            'width.px': isMobile ? screenWidth : screenWidth
          }"
          class="product-image"
          loading="eager"
        />
      </a>
    </div>
  </swiper-slide>

  <swiper-slide>
    <div class="slider-swiper-item" *noRender>
      <a [href]="'/kits/policarbonato-alveolar-kit'">
        <img
          src="{{
            isMobile
              ? 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-4-mobile.webp'
              : 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-4.webp'
          }}"
          alt="Confira nossas promoções"
          [ngStyle]="{
            'width.px': isMobile ? screenWidth : screenWidth
          }"
          class="product-image"
          loading="eager"
        />
      </a>
    </div>
  </swiper-slide>

  <swiper-slide>
    <div class="slider-swiper-item" *noRender>
      <a [href]="'/toldos/toldos-cortina'">
        <img
          src="{{
            isMobile
              ? 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-5-mobile.webp'
              : 'https://d20p8exwoll05u.cloudfront.net/assets/images/slider/banner-5.webp'
          }}"
          alt="Confira nossas promoções"
          [ngStyle]="{
            'width.px': isMobile ? screenWidth : screenWidth
          }"
          class="product-image"
          loading="eager"
        />
      </a>
    </div>
  </swiper-slide>
</swiper-container>
