<p-galleria
  [value]="images"
  [autoPlay]="true"
  [circular]="true"
  [numVisible]="isMobile ? 5 : 6"
  [containerStyle]="{ 'max-width': isMobile ? innerWidth : '550px' }"
  [showThumbnails]="true"
>
  <ng-template pTemplate="thumbnail" let-item>
    <ng-container
      [ngTemplateOutlet]="item.type === 'video' ? thumbVideo : thumbImg"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>

    <ng-template #thumbVideo let-item>
      <img
        *noRender
        [src]="item?.urlAPI"
        style="display: block"
        [alt]="'Thumbnail do produto selecionado'"
      />
    </ng-template>
    <ng-template #thumbImg let-item>
      <img
        *noRender
        [src]="item?.url"
        style="display: block"
        [alt]="'Thumbnail do produto selecionado'"
      />
    </ng-template>
  </ng-template>

  <ng-template pTemplate="item" let-item>
    <ng-container
      [ngTemplateOutlet]="item.type === 'video' ? videoItem : imgItem"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>

    <ng-template #videoItem let-item>
      <div class="galeria__video--container" *noRender>
        <app-youtube-video
          [url]="item.url"
          title="Vídeos do produto selecionado"
        ></app-youtube-video>
      </div>
    </ng-template>

    <ng-template #imgItem let-item>
      <div *ngIf="discount" class="products-with-discount">
        PRODUTO EM OFERTA: {{ discount }}%
      </div>
      <div
        *noRender
        class="zoom-container"
        (mouseenter)="zoomIn($event)"
        (mouseleave)="zoomOut($event)"
        (mousemove)="zoomMove($event)"
      >
        <img
          [src]="item?.url"
          style="display: block"
          width="500"
          height="500"
          [alt]="'Imagens do produto selecionado'"
          class="zoom-image"
          (click)="openFullscreen(item?.url)"
        />
      </div>
    </ng-template>
  </ng-template>
</p-galleria>

<div class="fullscreen" *ngIf="fullscreenImage" (click)="closeFullscreen()">
  <i class="pi pi-times close-icon" (click)="closeFullscreen()"></i>
  <img [src]="fullscreenImage" />
</div>
